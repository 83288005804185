import React, { useState, useEffect, ChangeEvent } from "react";
import gs from "../globalStyles.styles";
import { Button } from "@mui/material";
import s from "./productJsonVerifyPage.styles";
import { handleVerifyButtonClick } from "../../functions/apiVerify/jsonErrorAnalysis";
import { useAuth0 } from "@auth0/auth0-react";
import ROUTE_MAP from "../../routes/routeMap";
import { MessageOnSave } from "../../components/productTracker/productTrackerInterfaces";
import { JSONEditorVerify } from "../../components/jsonEditor/jsonEditorVerify";

const ProductJsonVerifyPage: React.FC = () => {
  const [json, setJson] = useState<string>("");
  const [response, setResponse] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<MessageOnSave | undefined>(undefined);
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const handleUpdateEntitySnapshot = (e: ChangeEvent<HTMLInputElement>) => {
    setJson(e.target.value);
  };

  const handleButtonClickWrapper = () => {
    handleVerifyButtonClick(
      json,
      setResponse,
      setMessage,
      getAccessTokenSilently,
    );
  };

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: ROUTE_MAP.VERIFY_JSON },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return (
    <gs.PageContainer>
      {isAuthenticated ? (
        <>
          <gs.Container>
            <gs.Title>Enter your Product Request</gs.Title>
            <JSONEditorVerify
              height="90%"
              width="80%"
              value={json}
              handleUpdate={handleUpdateEntitySnapshot}
            />
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "20rem",
                marginTop: "1rem",
                overflowY: "auto",
              }}
              disabled={!json}
              variant="contained"
              onClick={handleButtonClickWrapper}
            >
              Check Request
            </Button>
          </gs.Container>
          <gs.Container center={true}>
            {message?.type === "ERROR" || response ? (
              <>
                {message?.type === "ERROR" && (
                  <s.ResponseContainer type="error">
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "anywhere",
                        overflowY: "auto",
                        margin: "2rem",
                      }}
                    >
                      {message.message}
                    </pre>
                  </s.ResponseContainer>
                )}
                {response && (
                  <s.ResponseContainer type="response">
                    Response:{" "}
                    <pre
                      style={{
                        whiteSpace: "pre-wrap",
                        overflowWrap: "anywhere",
                        overflowY: "auto",
                        margin: "2rem",
                      }}
                    >
                      {response}
                    </pre>
                  </s.ResponseContainer>
                )}
              </>
            ) : (
              ""
            )}
          </gs.Container>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </gs.PageContainer>
  );
};

export default ProductJsonVerifyPage;
