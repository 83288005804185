import React from "react";
import gs from "../../pages/globalStyles.styles";
import s from "./productTrackerComponent.styles";
import { JSONEditor } from "../jsonEditor/jsonEditor";
import { Button } from "@mui/material";
import { ApiTierEvent, MessageOnSave } from "./productTrackerInterfaces";

interface ProductInfoProps {
  event: ApiTierEvent;
  status: string | null;
  entitySnapshot: string;
  handleUpdateEntitySnapshot: (json: string) => void;
  handleSaveClick: () => void;
  message: MessageOnSave | undefined;
  handleSubmitUpdate: () => void;
  saveClicked: boolean;
}

const ProductInfoComponent: React.FC<ProductInfoProps> = ({
  event,
  status,
  entitySnapshot,
  handleUpdateEntitySnapshot,
  handleSaveClick,
  message,
  handleSubmitUpdate,
  saveClicked,
}) => (
  <s.ProductContainer status={status}>
    <s.B>Product ID:</s.B> <gs.Text>{event.entity_id}</gs.Text>
    <s.B>Status:</s.B> <gs.Text>{status}</gs.Text>
    <s.B>Created At:</s.B> <gs.Text>{event.created_at}</gs.Text>
    {event.status === "SUCCESS" ? (
      <>
        <s.B>Message:</s.B>
        <gs.Text>{event.status_message}</gs.Text>
      </>
    ) : (
      <></>
    )}
    <s.B>Entity snapshot (click to edit):</s.B>
    <div style={{ marginTop: "1rem" }}>
      <JSONEditor
        width="100%"
        height="100%"
        value={entitySnapshot}
        handleUpdate={handleUpdateEntitySnapshot}
      />
    </div>
    <gs.Container padding={15}>
      <Button variant="contained" onClick={handleSaveClick}>
        Save
      </Button>
    </gs.Container>
    <gs.Container padding={15}>
      {message?.type === "ERROR" && (
        <s.MessageContainer isSuccess={false}>
          {message.message}
        </s.MessageContainer>
      )}
      {message?.type === "SUCCESS" && (
        <s.MessageContainer isSuccess={true}>
          {message.message}
        </s.MessageContainer>
      )}
    </gs.Container>
    <gs.Container padding={15}>
      <Button
        variant="contained"
        onClick={handleSubmitUpdate}
        disabled={!saveClicked}
      >
        Update Entity
      </Button>
    </gs.Container>
  </s.ProductContainer>
);

interface MissingProductInfoProps {
  status: string | null;
}

const MissingProductInfoComponent: React.FC<MissingProductInfoProps> = ({
  status,
}) => (
  <s.MissingProductContainer status={status}>
    <gs.Text>{status}</gs.Text>
  </s.MissingProductContainer>
);

const productTrackerComponents = {
  MissingProductInfoComponent,
  ProductInfoComponent,
};

export default productTrackerComponents;
