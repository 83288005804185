import React, { createContext, useContext, useEffect, useState } from "react";
import HealthStatus from "../components/status/healthStatus";

interface HealthCheckContextProps {
  children: React.ReactNode;
}

export interface HealthCheckStatus {
  healthStatus: HealthStatus;
  timestamp?: string;
}

interface HealthCheckStatuses {
  [name: string]: HealthCheckStatus;
}

const HealthCheckContext = createContext<HealthCheckStatuses | undefined>(
  undefined,
);

export const APPS_TO_HEALTHCHECK: {
  [name: string]: { url: string | undefined };
} = {
  "Product API Tier": {
    url: process.env.REACT_APP_PRODUCT_API_TIER_URL,
  },
};

export const useHealthCheck = () => {
  const context = useContext(HealthCheckContext);
  if (!context) {
    throw new Error("useHealthCheck must be used within a HealthCheckProvider");
  }
  return context;
};

export const HealthCheckProvider: React.FC<HealthCheckContextProps> = ({
  children,
}) => {
  const [healthCheckStatuses, setHealthCheckStatuses] =
    useState<HealthCheckStatuses>(
      Object.keys(APPS_TO_HEALTHCHECK).reduce(
        (previous, current) => ({
          ...previous,
          [current]: {
            healthStatus: HealthStatus.Loading,
            timestamp: new Date().toLocaleString(),
          },
        }),
        {},
      ),
    );

  const updateHealthStatus = (name: string, url: string | undefined) => {
    fetch(url + `/health/liveness`)
      .then((response) => response.status)
      .then((livenessStatus) => {
        fetch(url + `/health/readiness`)
          .then((response) => response.status)
          .then((readinessStatus) => {
            setHealthCheckStatuses((currentState: HealthCheckStatuses) => {
              const healthStatus =
                livenessStatus === 200 && readinessStatus === 200
                  ? HealthStatus.Healthy
                  : HealthStatus.Down;
              const timestamp = new Date().toLocaleString();
              let newState = { ...currentState };
              newState[name] = { healthStatus, timestamp };
              return newState;
            });
          })
          .catch((error) => {
            console.error(`error setting healthstatuses ${error}`);
            setHealthCheckStatuses((currentState: HealthCheckStatuses) => {
              const timestamp = new Date().toLocaleString();
              let newState = { ...currentState };
              newState[name] = { healthStatus: HealthStatus.Down, timestamp };
              return newState;
            });
          });
      })
      .catch((error) => {
        console.error(`error doing healthcheck ${error}`);
        setHealthCheckStatuses((currentState: HealthCheckStatuses) => {
          const timestamp = new Date().toLocaleString();
          let newState = { ...currentState };
          newState[name] = { healthStatus: HealthStatus.Down, timestamp };
          return newState;
        });
      });
  };

  useEffect(() => {
    const fetchData = () => {
      Object.keys(APPS_TO_HEALTHCHECK).map((key) => {
        const url = APPS_TO_HEALTHCHECK[key].url;
        return updateHealthStatus(key, url);
      });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <HealthCheckContext.Provider value={healthCheckStatuses}>
      {children}
    </HealthCheckContext.Provider>
  );
};

export const getStatusFromAll = (healthCheckStatuses: HealthCheckStatuses) => {
  let healthStatus = HealthStatus.Healthy;
  for (const key of Object.keys(healthCheckStatuses)) {
    if (
      healthCheckStatuses[key].healthStatus === HealthStatus.Degraded &&
      healthStatus === HealthStatus.Healthy
    ) {
      healthStatus = HealthStatus.Degraded;
    }
    if (healthCheckStatuses[key].healthStatus === HealthStatus.Down) {
      healthStatus = HealthStatus.Down;
    }
    if (healthCheckStatuses[key].healthStatus === HealthStatus.Loading) {
      return HealthStatus.Loading;
    }
  }
  return healthStatus;
};
