import {
  HealthCheckComponent,
  HealthCheckTableComponent,
} from "../../components/status/healthCheck/healthCheckComponent";
import s from "./healthCheck.styles";
import gs from "../globalStyles.styles";
import IncidentComponent from "../../components/status/incidentReport/incidentReport";
import { ReactComponent as JackHammerSVG } from "../../assets/images/jackhammer.svg";
import { ReactComponent as ExplosionSVG } from "../../assets/images/explosion.svg";
import { ReactComponent as StandingWithShovelSVG } from "../../assets/images/standing-with-shovel.svg";
import {
  getStatusFromAll,
  useHealthCheck,
} from "../../contexts/healthCheckContext";
import { useEffect, useState } from "react";
import HealthStatus from "../../components/status/healthStatus";
import Spinner from "../../components/spinner/spinner";

const HealthCheckPage: React.FC = () => {
  const healthStatuses = useHealthCheck();
  const [allHealthStatus, setAllHealthStatus] = useState<HealthStatus>(
    HealthStatus.Loading,
  );
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    setAllHealthStatus(getStatusFromAll(healthStatuses));
  }, [healthStatuses]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (pageLoading) {
    return (
      <gs.PageContainer>
        <gs.Container center={true}>
          <Spinner />
        </gs.Container>
      </gs.PageContainer>
    );
  }

  return (
    <gs.PageContainer>
      <gs.Container center={true}>
        {allHealthStatus === HealthStatus.Degraded ? (
          <JackHammerSVG style={{ width: 100, height: 100 }} />
        ) : allHealthStatus === HealthStatus.Down ? (
          <ExplosionSVG style={{ width: 100, height: 100 }} />
        ) : allHealthStatus === HealthStatus.Loading ? (
          <Spinner />
        ) : (
          <StandingWithShovelSVG style={{ width: 100, height: 100 }} />
        )}
        <s.Section>
          <s.HealthCheckWrapper>
            <HealthCheckComponent />
          </s.HealthCheckWrapper>
          <s.HealthCheckWrapper>
            <HealthCheckTableComponent />
          </s.HealthCheckWrapper>
          <s.HealthCheckWrapper>
            <IncidentComponent />
          </s.HealthCheckWrapper>
        </s.Section>
      </gs.Container>
    </gs.PageContainer>
  );
};

export default HealthCheckPage;
