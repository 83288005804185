import styled from "styled-components";
interface ResponseType {
  type: string | null;
}

const ResponseContainer = styled.div<ResponseType>`
  width: 80%;
  max-height: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  border: ${(props) =>
    props.type === "error"
      ? "3px solid orange"
      : props.type === "response"
        ? "3px solid green"
        : "3px solid grey"};
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
`;

const RequestButton = styled.button`
  display: "flex";
  justify-content: "center";
  align-items: "center";
  width: "20rem";
  margin-top: "rem";
`;
const styles = { RequestButton, ResponseContainer };

export default styles;
