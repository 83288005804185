import React, { useEffect } from "react";
import Spinner from "../../components/spinner/spinner";
import gs from "../globalStyles.styles";
import s from "./redirectPage.styles";

type Props = {
  url: string;
};

const RedirectPage: React.FC<Props> = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.open(props.url);
    }, 1000);
  }, [props]);

  return (
    <gs.PageContainer>
      <gs.Container center={true}>
        <gs.Title>Redirecting...</gs.Title>
        <Spinner></Spinner>
        <s.Message>The new page will open in a new tab</s.Message>
      </gs.Container>
    </gs.PageContainer>
  );
};

export default RedirectPage;
