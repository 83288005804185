import { ApiTierEventProp, MessageOnSave } from "./productTrackerInterfaces";
import { useEffect, useState } from "react";
import { handleVerifyButtonClick } from "../../functions/apiVerify/jsonErrorAnalysis";
import productTrackerComponents from "./productTrackerComponents";

export const ProductTrackerComponent: React.FC<ApiTierEventProp> = ({
  event,
  status,
  saveClicked,
  setSaveClicked,
  getAccessTokenSilently,
}) => {
  const [entitySnapshot, setEntitySnapshot] = useState("");
  const [response, setResponse] = useState<string | undefined>(undefined);
  const [messageOnSave, setMessageOnSave] = useState<MessageOnSave | undefined>(
    undefined,
  );

  useEffect(() => {
    const parsedJson = event != null ? JSON.parse(event?.entity) : null;
    if (parsedJson != null) {
      setEntitySnapshot(JSON.stringify(parsedJson, null, 4));
    }
  }, [event]);

  const handleSubmitUpdate = async () => {
    const url = `/track/v1/product/${event?.entity_id}`;
    const token = await getAccessTokenSilently();
    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: entitySnapshot,
      });

      if (response.ok) {
        // Request was successful
        const responseData = await response.json();
        setMessageOnSave({
          type: "SUCCESS",
          message: `Sucessfully updated: ${responseData}`,
        });
      } else {
        setMessageOnSave({
          type: "ERROR",
          message: `Unsucessfully updated ${response.statusText}`,
        });
      }
    } catch (error) {
      setMessageOnSave({ type: "ERROR", message: `${error}` });
    }
  };

  const handleSaveClick = async () => {
    const json = await handleVerifyButtonClick(
      entitySnapshot,
      setResponse,
      setMessageOnSave,
      getAccessTokenSilently,
    );
    setEntitySnapshot(json);
  };

  useEffect(() => {
    if (response) {
      setSaveClicked(true);
    } else {
      setSaveClicked(false);
    }
  }, [response, setSaveClicked]);

  const handleUpdateEntitySnapshot = (json: string) => {
    setSaveClicked(false);
    setEntitySnapshot(json);
  };

  return (
    <>
      {event ? (
        <productTrackerComponents.ProductInfoComponent
          event={event}
          status={status}
          entitySnapshot={entitySnapshot}
          handleUpdateEntitySnapshot={handleUpdateEntitySnapshot}
          handleSaveClick={handleSaveClick}
          message={messageOnSave}
          handleSubmitUpdate={handleSubmitUpdate}
          saveClicked={saveClicked}
        />
      ) : (
        status !== "refresh" && (
          <productTrackerComponents.MissingProductInfoComponent
            status={status}
          />
        )
      )}
    </>
  );
};
