import { useEffect, useState } from "react";
import { ProductEventList } from "../../components/productTracker/productTrackerInterfaces";

const updateEventList = async (
  url: string,
  setEventList: React.Dispatch<React.SetStateAction<ProductEventList>>,
  getAccessTokenSilently: any,
) => {
  try {
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(`${url}?consumer_id=product_processing_tier`, {
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (response.status >= 200 && response.status < 300) {
      const list = await response.json();
      setEventList(() => ({
        event_list: list,
      }));
    } else {
      throw new Error(`HTTP status: ${response.status}`);
    }
  } catch (error) {
    console.error(error);
  }
};

const fetchData = async (
  apiUrl: string,
  setEventList: React.Dispatch<React.SetStateAction<ProductEventList>>,
  getAccessTokenSilently: any,
) => {
  try {
    await updateEventList(apiUrl, setEventList, getAccessTokenSilently);
  } catch (error) {
    console.error(error);
  }
};

const pollData = (
  apiUrl: string,
  setEventList: React.Dispatch<React.SetStateAction<ProductEventList>>,
  getAccessTokenSilently: any,
) => {
  console.log("polling");
  fetchData(apiUrl, setEventList, getAccessTokenSilently);
  const intervalId = setInterval(
    () => fetchData(apiUrl, setEventList, getAccessTokenSilently),
    300000,
  );
  return () => {
    clearInterval(intervalId);
  };
};

export const useProductTracker = (getAccessTokenSilently: any) => {
  const [productEventList, setEventList] = useState<ProductEventList>({
    event_list: [],
  });

  useEffect(() => {
    const apiUrl = "/track/v1/event/status";
    const cleanup = pollData(apiUrl, setEventList, getAccessTokenSilently);
    return cleanup;
  }, [getAccessTokenSilently, setEventList]);

  return productEventList;
};
