import styled from "styled-components";

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export const ContentContainer = styled.div`
  flex: 1;
  padding: 10px;
`;

export const SidebarContainer = styled.div<{ isOpen: boolean }>`
  flex: ${(props) => (props.isOpen ? "1" : "0 0 0")};
  max-width: ${(props) => (props.isOpen ? "200px" : "0")};
  transition: max-width 0.3s ease; /* Add a smooth transition for better visual effect */
`;

const styles = { AppContainer, ContentContainer, SidebarContainer };
export default styles;
