import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

interface JSONEditorProps {
  value: string;
  width: string;
  height: string;
  handleUpdate: (updatedValue: string) => void;
}

interface JsonObject {
  [key: string]: any;
}

export const JSONEditor: React.FC<JSONEditorProps> = ({
  value,
  handleUpdate,
  width,
  height,
}) => {
  const [editedObject, setEditedObject] = useState<JsonObject>({});

  useEffect(() => {
    if (value) {
      try {
        const parsedObject = JSON.parse(value);
        setEditedObject(parsedObject);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [value]);

  const handleFieldChange = (key: string, newValue: any) => {
    setEditedObject((prevState) => {
      const nextState = deepCopy(prevState);
      updateValue(nextState, key.split("."), newValue);
      console.log("Updated State:", nextState);
      return nextState;
    });
    handleUpdate(JSON.stringify(editedObject));
  };

  function deepCopy(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  function updateValue(obj: any, keys: string[], newValue: any) {
    let currentObj = obj;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (currentObj.hasOwnProperty(key)) {
        if (i === keys.length - 1) {
          currentObj[key] = newValue;
        } else {
          currentObj = currentObj[key];
        }
      } else {
        if (i === keys.length - 1) {
          currentObj[key] = newValue;
        } else {
          currentObj[key] = {};
          currentObj = currentObj[key];
        }
      }
    }
  }

  const renderField = (key: string, value: any) => {
    if (Array.isArray(value)) {
      return value.map((variant: any, index: number) => (
        <div key={index}>{renderField(`${key}.${index}`, variant)}</div>
      ));
    } else if (typeof value === "object") {
      return (
        <div style={{ marginLeft: 40 }}>
          {Object.entries(value).map(([variantKey, variantValue]) => (
            <div key={variantKey}>
              <strong>{variantKey}:</strong>{" "}
              {renderField(`${key}.${variantKey}`, variantValue)}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <TextField
          fullWidth
          value={value}
          onChange={(e) => handleFieldChange(key, e.target.value)}
        />
      );
    }
  };

  return (
    <div
      style={{
        width: width,
        height: height,
        overflowY: "auto",
      }}
    >
      {Object.entries(editedObject).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {renderField(key, value)}
        </div>
      ))}
    </div>
  );
};
