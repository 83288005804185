import { MessageOnSave } from "../../components/productTracker/productTrackerInterfaces";
import { jsonVerifier } from "./verifyJson";

interface FrontierErrorResponse {
  id: string;
  status: string;
  code: string;
  title: string;
  detail: string;
  source: {
    pointer: string;
  };
}

const handleSuccess = async (
  responseJson: Response,
  setResponse: React.Dispatch<React.SetStateAction<string | undefined>>,
  setMessageOnSave: React.Dispatch<
    React.SetStateAction<MessageOnSave | undefined>
  >,
) => {
  setResponse(JSON.stringify(await responseJson.json(), null, 1));
  const message: MessageOnSave = {
    type: "SUCCESS",
    message: "JSON saved Successfully",
  };
  setMessageOnSave(message);
};

const handleUnauthorizedException = (error: FrontierErrorResponse) => {
  //code of we ever want to handle different types of erros separately
};

const handleJsonError = (error: FrontierErrorResponse) => {
  //code of we ever want to handle different types of erros separately
};

const handleErrorResponse = async (
  error: any,
  setResponse: React.Dispatch<React.SetStateAction<string | undefined>>,
  setMessageOnSave: React.Dispatch<
    React.SetStateAction<MessageOnSave | undefined>
  >,
) => {
  if (error instanceof Response) {
    try {
      const errorJson = await error.json();
      if (errorJson) {
        const errorsArray = errorJson.errors;
        for (const error of errorsArray) {
          if (error.code === "RequestValidationError") {
            handleJsonError(error);
          } else {
            handleUnauthorizedException(error);
          }
        }
      }
      const message: MessageOnSave = {
        type: "ERROR",
        message: JSON.stringify(errorJson, null, 1),
      };
      setMessageOnSave(message);
    } catch (jsonError) {
      setMessageOnSave({
        type: "ERROR",
        message: `Error parsing JSON response: ${jsonError}`,
      });
    }
  } else {
    setMessageOnSave({ type: "ERROR", message: error.message });
  }
  setResponse(undefined);
};

export const handleVerifyButtonClick = async (
  json: string,
  setResponse: React.Dispatch<React.SetStateAction<string | undefined>>,
  setMessageOnSave: React.Dispatch<
    React.SetStateAction<MessageOnSave | undefined>
  >,
  getAccessTokenSilently: any,
): Promise<string> => {
  try {
    const responseJson = await jsonVerifier(json, getAccessTokenSilently);
    handleSuccess(responseJson, setResponse, setMessageOnSave);
    return json;
  } catch (error: any) {
    handleErrorResponse(error, setResponse, setMessageOnSave);
    return "";
  }
};
