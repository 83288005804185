import { TextField } from "@mui/material";
import React from "react";

interface JSONEditorProps {
  value: string;
  width: string;
  height: string;
  handleUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const JSONEditorVerify: React.FC<JSONEditorProps> = ({
  value,
  handleUpdate,
  width,
  height,
}) => {
  return (
    <div
      style={{
        width: width,
        height: height,
        overflowY: "auto",
      }}
    >
      <TextField
        multiline={true}
        rows={50}
        fullWidth
        value={value}
        onChange={handleUpdate}
      />
    </div>
  );
};
