import styled from "styled-components";

interface ContainerProps {
  center?: boolean;
  padding?: number;
}

const PageContainer = styled.div`
  display: flex;
  height: 100%;
  background-color: #f8f8f8;
  flex-direction: row;
`;

const Title = styled.h1`
  font-size: 40px;
  color: #333333;
  margin-bottom: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h1`
  font-size: 25px;
  color: #333333;
  margin-bottom: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  text-align: center;
  font-size: 17px;
  color: #333333;
`;

const Button = styled.a`
  display: inline-block;
  padding: 12px 24px;
  background-color: #000000;
  color: #ffffff;
  font-size: 18px;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #333333;
  }
`;

const Container = styled.div<ContainerProps>`
  margin: 0 auto;
  flex: 1;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  justify-content: ${(props) => (props.center ? "center" : "")};
  background-color: #ffffff;
  padding-top: ${(props) =>
    props.padding != null ? props.padding + "px" : "50px"};
  width: 100%;
`;

const styles = { PageContainer, Title, Text, Button, Container, Subtitle };
export default styles;
