import styled from "styled-components";

interface HollowBulletProps {
  isDegraded?: boolean;
}

const IncidentReportContainer = styled.div`
  font-family: Arial, sans-serif;
  padding: 30px;
  border: 3px solid #ccc;
  border-radius: 10px;
  margin: auto;
  margin-top: 10px;
  width: 755px;
`;

const IncidentListItem = styled.li`
  margin-bottom: 5px;
`;

const IncidentDate = styled.p`
  text-align: center;
  font-weight: bold;
`;
const DateLine = styled.span`
  border-bottom: 2px solid #aa3218;
  display: inline-block;
  margin-right: 5px;
`;

const HollowBulletList = styled.ul`
  list-style: none;
  margin: 10px;
`;

const HollowBulletListItem = styled.li`
  position: relative;
  padding-left: 20px;
`;

const HollowBullet = styled.span<HollowBulletProps>`
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border: 2px solid
    ${(props) => (props.isDegraded ? "orange" : "red") || "black"};
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 10px;
`;

const IncidentContainer = styled.div``;

const styles = {
  IncidentReportContainer,
  IncidentListItem,
  IncidentContainer,
  DateLine,
  IncidentDate,
  HollowBulletList,
  HollowBulletListItem,
  HollowBullet,
};

export default styles;
